import CoreProTable, { CoreProTableState } from '../../core/components/CoreProTable';
import { DailyIncomeProvider, MchDailyIncome } from '../../domain/report-provider/dailyIncomeProvider';
import React, { useEffect, useRef, useState } from 'react';
import { ProCoreActionType } from '@ant-design/pro-utils';
import { FormInstance } from 'antd/lib/form';
import { usePageState } from '../../core/hooks/usePageState';
import { Checkbox, PageHeader, Table } from 'antd';
import { transfer2LocalDate } from '../../core/utils/timeFormat';
import PaginationTransformer from '../../core/utils/paginationTransformer';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalStoreMerge } from '../../../stores/merge';
import { UserStore } from '../../../stores/user';
import SystemIdSearchList from '../../domain/components/system-search-list/SystemIdSearchList';
import * as _ from 'lodash';
import { state } from '@antv/g2plot/lib/adaptor/common';
import { TableUtilColumn } from '../../core/utils/tableUtilColumn';
import { DateRangeStateType } from '../../core/utils/tableUtilType';
import BigNumber from 'bignumber.js';
import ExcelDownloadFormButton from '../../core/components/ExcelDownloadFormButton';

interface PageOp extends CoreProTableState<MchDailyIncome> {
}

// eslint-disable-next-line
export default function() {
  // const dispatch = useDispatch();
  const actionRef = useRef<ProCoreActionType>();
  const searchFormRef = useRef<FormInstance>();
  const { t } = useTranslation();
  const sorterState = usePageState<{
    sorterString: string;
    creditAmountSorter: 'descend' | 'ascend' | null | undefined;
    creditAmountFromMchSorter: 'descend' | 'ascend' | null | undefined;
    creditFeeSorter: 'descend' | 'ascend' | null | undefined;
    payforAmountSorter: 'descend' | 'ascend' | null | undefined;
    payforFeeSorter: 'descend' | 'ascend' | null | undefined;
    transferBackAmountSorter: 'descend' | 'ascend' | null | undefined;
    transferBackFeeSorter: 'descend' | 'ascend' | null | undefined;
    collectAmountSorter: 'descend' | 'ascend' | null | undefined;
    collectFeeSorter: 'descend' | 'ascend' | null | undefined;
    totalFeeSorter: 'descend' | 'ascend' | null | undefined;
  }>('SorterState', {
    sorterString: '',
    creditAmountSorter: undefined,
    creditAmountFromMchSorter: undefined,
    creditFeeSorter: undefined,
    payforAmountSorter: undefined,
    payforFeeSorter: undefined,
    transferBackAmountSorter: undefined,
    transferBackFeeSorter: undefined,
    collectAmountSorter: undefined,
    collectFeeSorter: undefined,
    totalFeeSorter: undefined,
  });
  const checkedState = usePageState<{
    checked: boolean;
  }>('CheckedState', {
    checked: true,
  });
  const user = useSelector<GlobalStoreMerge, UserStore>((state) => state.user); // 取得當前使用者資訊
  const pageOp = usePageState<PageOp>('PAGE_REMEMBER', {
    params: {},
    collapsed: false,
    pagination: {
      pageSize: 10,
      current: 1,
    },
    needRequest: true,
  });

  const dateRangeState = useState<DateRangeStateType>();
  const dateRangeColumn = TableUtilColumn.getDateRangeColumn({
    defaultDateRange: 30,
    defaultDate: 1,
    title: t('report.reportDate'),
    pageOp,
    state: dateRangeState,
  });

  const totalState = usePageState<{
    creditAmount: number | string;
    creditAmountFromMch: number | string;
    creditFee: number | string;
    payforAmount: number | string;
    payforFee: number | string;
    transferBackAmount: number | string;
    transferBackFee: number | string;
    collectAmount: number | string;
    collectFee: number | string;
    totalFee: number | string;
  }>('TotalState', {
    creditAmount: 0,
    creditAmountFromMch: 0,
    creditFee: 0,
    payforAmount: 0,
    payforFee: 0,
    transferBackAmount: 0,
    transferBackFee: 0,
    collectAmount: 0,
    collectFee: 0,
    totalFee: 0,
  });

  useEffect(() => {
    searchFormRef.current?.setFieldsValue(pageOp.get().params);
  }, [searchFormRef, pageOp]);
  const isMchUser = !!user.systemId;

  async function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    const sortArr: { field: any; order: string; }[] = [];
    if (sorter.length > 1) {
      _.forEach(sorter, (row) => {
        if (!_.isNil(row.order)) {
          const orderType = row.order == 'descend' ? 'DESC' : 'ASC';
          sortArr.push({ field: row.field, order: orderType });
        }
      });
    } else {
      if (!_.isNil(sorter.order)) {
        const orderType = sorter.order == 'descend' ? 'DESC' : 'ASC';
        sortArr.push({ field: sorter.field, order: orderType });
      }
    }

    pageOp.update((v) => {
      sorterState.update({
        sorterString: JSON.stringify(sortArr),
        creditAmountSorter: sorter.field == 'creditAmount' ? sorter.order : undefined,
        creditAmountFromMchSorter: sorter.field == 'creditAmountFromMch' ? sorter.order : undefined,
        creditFeeSorter: sorter.field == 'creditFee' ? sorter.order : undefined,
        payforAmountSorter: sorter.field == 'payforAmount' ? sorter.order : undefined,
        payforFeeSorter: sorter.field == 'payforFee' ? sorter.order : undefined,
        transferBackAmountSorter: sorter.field == 'transferBackAmount' ? sorter.order : undefined,
        transferBackFeeSorter: sorter.field == 'transferBackFee' ? sorter.order : undefined,
        collectAmountSorter: sorter.field == 'collectAmount' ? sorter.order : undefined,
        collectFeeSorter: sorter.field == 'collectFee' ? sorter.order : undefined,
        totalFeeSorter: sorter.field == 'totalFee' ? sorter.order : undefined,
      });
      return { ...v, needRequest: true, ...state };
    });
  }

  return (
    <>
      <PageHeader title={'日結算表'} subTitle={''} extra={<></>} />
      <CoreProTable<MchDailyIncome>
        bordered
        rowKey={(record) => `${record.reportDate}-${record.systemId}`}
        actionRef={actionRef as any}
        tableState={pageOp.get()}
        scroll={{ x: 'fixed' }}
        formRef={searchFormRef}
        search={{
          layout: 'vertical',
          defaultCollapsed: false,
        }}
        toolBarRender={() => [
          <ExcelDownloadFormButton
            filePrefix={'daily_income'}
            getExcelFile={async (start, end) => {
              let res;
              const param = {
                start: dateRangeColumn.getParamAndUpdatePage().start,
                end: dateRangeColumn.getParamAndUpdatePage().end,
                hideLessZero: checkedState.get().checked,
                sorter: sorterState.get().sorterString,
              };
              if (isMchUser) {
                res = await DailyIncomeProvider.getMchList(param).catch((e) => {
                  console.error(e);
                  throw e;
                });
              } else {
                res = await DailyIncomeProvider.getAdminExcel(param).catch((e) => {
                  console.error(e);
                  throw e;
                });
              }
              return res.data;
            }}
          />,
        ]}
        updateTableState={(state: any) => {
          pageOp.update((v) => ({ ...v, ...state }));
        }}
        requestData={async (params: any) => {
          let res;

          const param = {
            ...PaginationTransformer.paramTransfer(params),
            start: dateRangeColumn.getParamAndUpdatePage().start,
            end: dateRangeColumn.getParamAndUpdatePage().end,
            hideLessZero: checkedState.get().checked,
            sorter: sorterState.get().sorterString,
          };
          if (isMchUser) {
            res = await DailyIncomeProvider.getMchList(param).catch((e) => {
              console.error(e);
              throw e;
            });
          } else {
            res = await DailyIncomeProvider.getAdminList(param).catch((e) => {
              console.error(e);
              throw e;
            });
          }
          const pageSummary = {
            creditAmount: 0,
            creditAmountFromMch: 0,
            creditFee: 0,
            payforAmount: 0,
            payforFee: 0,
            transferBackAmount: 0,
            transferBackFee: 0,
            collectAmount: 0,
            collectFee: 0,
            totalFee: 0,
          };
          const itemList = res.data.items;
          for (const item of itemList) {
            pageSummary.creditAmount = new BigNumber(pageSummary.creditAmount)
              .plus(item.creditAmount)
              .toNumber();
            pageSummary.creditAmountFromMch = new BigNumber(pageSummary.creditAmountFromMch)
              .plus(item.creditAmountFromMch)
              .toNumber();
            pageSummary.creditFee = new BigNumber(pageSummary.creditFee)
              .plus(item.creditFee)
              .toNumber();
            pageSummary.payforAmount = new BigNumber(pageSummary.payforAmount)
              .plus(item.payforAmount)
              .toNumber();
            pageSummary.payforFee = new BigNumber(pageSummary.payforFee)
              .plus(item.payforFee)
              .toNumber();
            pageSummary.transferBackAmount = new BigNumber(pageSummary.transferBackAmount)
              .plus(item.transferBackAmount)
              .toNumber();
            pageSummary.transferBackFee = new BigNumber(pageSummary.transferBackFee)
              .plus(item.transferBackFee)
              .toNumber();
            pageSummary.collectAmount = new BigNumber(pageSummary.collectAmount)
              .plus(item.collectAmount)
              .toNumber();
            pageSummary.collectFee = new BigNumber(pageSummary.collectFee)
              .plus(item.collectFee)
              .toNumber();
            pageSummary.totalFee = new BigNumber(pageSummary.totalFee)
              .plus(item.totalFee)
              .toNumber();
          }
          totalState.update(pageSummary);

          return PaginationTransformer.responseTransfer(res);
        }}
        onChange={onChange}
        onReset={() => {
          dateRangeColumn.reset();
          checkedState.update({
            checked: true,
          });
          pageOp.update((v) => {
            v.needRequest = false;
            v.params = {};
            return v;
          });
        }}
        columns={[
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            align: 'center',
            title: t('payment.report_date'),
            dataIndex: 'reportDate',
            render: (text, row) => transfer2LocalDate(text as any),
            fixed: 'left',
          },
          {
            hideInSearch: isMchUser,
            hideInTable: false,
            width: 100,
            ellipsis: true,
            dataIndex: 'systemId',
            title: t('payment.system_id'),
            renderFormItem: (_: any, row, form: FormInstance<any>) => (
              <SystemIdSearchList
                onChange={(value) =>
                  form.setFieldsValue({ [_.dataIndex as string]: value })
                }
                systemId={pageOp.get().params.systemId}
              />
            ),
          },
          dateRangeColumn.column,
          {
            hideInSearch: false,
            hideInTable: true,
            width: 100,
            ellipsis: true,
            dataIndex: 'hideLessZero',
            title: '隱藏手續費小於1的商戶',
            renderFormItem: (value) => (
              <Checkbox
                onChange={(value) => {
                  checkedState.update({
                    checked: value.target.checked,
                  });
                  return;
                }}
                checked={checkedState.get().checked}
              />
            ),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'token',
            title: t('payment.token'),
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'creditAmount',
            title: t('report.total_credit_amount'),
            sorter: true,
            defaultSortOrder: sorterState.get().creditAmountSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'creditAmountFromMch',
            title: t('report.total_credit_amount_from_mch'),
            sorter: true,
            defaultSortOrder: sorterState.get().creditAmountFromMchSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'creditFee',
            sorter: true,
            title: t('report.total_credit_fee'),
            defaultSortOrder: sorterState.get().creditFeeSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'payforAmount',
            title: t('report.total_payfor_amount'),
            sorter: true,
            defaultSortOrder: sorterState.get().payforAmountSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'payforFee',
            title: t('report.total_payfor_fee'),
            sorter: true,
            defaultSortOrder: sorterState.get().payforFeeSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'transferBackAmount',
            title: t('report.total_transferback_amount'),
            sorter: true,
            defaultSortOrder: sorterState.get().transferBackAmountSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'transferBackFee',
            title: t('report.total_transferback_fee'),
            sorter: true,
            defaultSortOrder: sorterState.get().transferBackFeeSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'collectAmount',
            title: t('report.total_collect_amount'),
            sorter: true,
            defaultSortOrder: sorterState.get().collectAmountSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'collectFee',
            title: t('report.total_collect_fee'),
            sorter: true,
            defaultSortOrder: sorterState.get().collectFeeSorter,
          },
          {
            hideInSearch: true,
            hideInTable: false,
            width: 100,
            dataIndex: 'totalFee',
            title: t('report.total_fee'),
            sorter: true,
            defaultSortOrder: sorterState.get().totalFeeSorter,
          },
        ]}
        summary={() => (
          <Table.Summary.Row style={{ backgroundColor: '#d9d9d9' }}>
            <Table.Summary.Cell index={1} align={'center'}>
              Total
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}></Table.Summary.Cell>
            <Table.Summary.Cell index={3}></Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              {totalState.get().creditAmount}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5}>
              {totalState.get().creditAmountFromMch}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6}>
              {totalState.get().creditFee}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7}>
              {totalState.get().payforAmount}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8}>
              {totalState.get().payforFee}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={9}>
              {totalState.get().transferBackAmount}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={10}>
              {totalState.get().transferBackFee}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={11}>
              {totalState.get().collectAmount}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={12}>
              {totalState.get().collectFee}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={13}>
              {totalState.get().totalFee}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    </>
  );
};
