import adminRequest from '../../../config/adminRequest';
import { ReportUri } from '../../core/configs/services';
import { AdminListResponse } from '../models/adminListResponse';

export interface MchDailyIncome {
  reportDate: string;
  systemId: string;
  token:string;
  creditAmount: number | string;
  creditAmountFromMch: number | string;
  creditFee: number | string;
  payforAmount: number | string;
  payforFee: number | string;
  transferBackAmount: number | string;
  transferBackFee: number | string;
  collectAmount: number | string;
  collectFee: number | string;
  totalFee: number | string;
  createdAt: Date;
  createdBy: string;
}

export class DailyIncomeProvider {
  static async getMchList(params: any) {
    const res = await adminRequest.get<AdminListResponse<MchDailyIncome>>(
      ReportUri('mch/daily-income'),
      { params: params },
    );
    return res.data;
  }

  static async getAdminList(params: any) {
    const res = await adminRequest.get<AdminListResponse<MchDailyIncome>>(
      ReportUri('admin/daily-income'),
      { params: params },
    );
    return res.data;
  }

  static async getAdminExcel(params: any) {
    const res = await adminRequest.get<AdminListResponse<MchDailyIncome>>(
      ReportUri('admin/daily-income/excel'),
      { params: params },
    );
    return res.data;
  }
}
