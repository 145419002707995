import adminRequest from '../../../config/adminRequest';
import { AdminListResponse } from '../models/adminListResponse';
import { ReportUri } from '../../core/configs/services';

export interface TotalUsdtChange {
  txnId: number;
  systemId: string;
  transactionHash: string;
  walletAddress: string;
  tradeCode: string;
  availableAmount: number;
  txnAmount: number;
  availableAfterBalance: number | string;
  txnAfterBalance: number | string;
  comment: string;
  sourceTxnId: number;
  sourceCreatedAt: Date;
  createdAt: Date;
  createdBy: string;
}

export class TotalUsdtChangeProvider {
  static async getMchList(params: any) {
    const res = await adminRequest.get<AdminListResponse<TotalUsdtChange>>(
      ReportUri('mch/report/mch-wallet-change-log'),
      { params: params },
    );
    return res.data;
  }

  static async getAdminList(params: any) {
    const res = await adminRequest.get<AdminListResponse<TotalUsdtChange>>(
      ReportUri('admin/report/mch-wallet-change-log'),
      { params: params },
    );
    return res.data;
  }

  static async getAdminExcel(params: any) {
    const res = await adminRequest.get<AdminListResponse<TotalUsdtChange>>(
      ReportUri('admin/report/mch-wallet-change-log/excel'),
      { params: params },
    );
    return res.data;
  }
}
